import React from "react"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"

const HomePage = () => {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						siteUrl
					}
				}
			}
		`
	)

	var structuredData = {
		"@context": "https://schema.org",
		"@type": "Organization",
		"sameAs": [
			"https://www.instagram.com/iamnetph/",
			"https://twitter.com/iamnetph/",
			"https://www.facebook.com/iamnetph/"
		],
		"url": site.siteMetadata.siteUrl,
		"description": site.siteMetadata.description,
		"mainEntityOfPage": site.siteMetadata.title,
		"address": {
			"@type": "PostalAddress",
			"addressRegion": "Metro Manila",
			"postalCode": "1403",
			"addressCountry": "PH",
			"addressLocality": "Caloocan City",
			"streetAddress": "704 Rizal Ave. ext."
		},
		"email": "info@iamnet.comph",
		"name": site.siteMetadata.title,
		"image": site.siteMetadata.siteUrl+"/images/logo.png",
		"logo": site.siteMetadata.siteUrl+"/images/logo-square.png",
		"@id": site.siteMetadata.siteUrl
	}

	return (
			<Layout activePath='' pageTitle="Home" structuredData={structuredData}></Layout>
		)

}


export default HomePage;
